<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div>
      <div *ngIf="mode === CreateEditMode.CREATE; else editMode">
        <div class="d-flex justify-content-center"><h1>Create an article</h1></div>
      </div>
      <ng-template #editMode>
        <div class="d-flex justify-content-center"><h1>Edit an article</h1></div>
      </ng-template>
      <form [formGroup]="formGroup">
        <div class="form-group d-flex justify-content-center">
          <input type="text" formControlName="headline"/>
        </div>
        <div>
          <button mat-raised-button color="accent" [disabled]="!article" (click)="openAddContentDialog()">Add Content</button>
        </div>

        <div *ngIf="mode === CreateEditMode.EDIT">
          <mat-card *ngFor="let content of article.content" class="mt-3">
            <mat-card-content>{{content.textContent}}</mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="openEdit(content.textContent, content.id)">Edit</button>
            </mat-card-actions>
          </mat-card>
        </div>


        <!--Submit Section-->
        <div class="form-group d-flex justify-content-center mt-5">
          <button *ngIf="mode === CreateEditMode.EDIT; else templateRef"  class="btn btn-primary btn-lg"
                  (click)="edit()">Edit
          </button>
          <ng-template #templateRef>
            <button  class="btn btn-primary btn-lg"
                     (click)="submit()">
              Create
            </button>
          </ng-template>

        </div>
      </form>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>

