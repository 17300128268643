<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <h1 class="display-4">Complete Your Registration</h1>

    <div class="form-group">
      <p>A verification code has been sent to your registered email address. Use the verification code here to very your
        email address</p>
    </div>

    <div class="form-group">
      <p>Enter verification code</p>
      <input class="form-control form-control-lg" type="text"
             [(ngModel)]="verificationCode"
             name="verificationCode"/>
    </div>

    <div class="form-group">
      <button type="button" class="btn btn-primary" (click)="submit()">Submit</button>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>


