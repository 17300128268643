<div class="row bg-primary-dark">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <nav class="navbar navbar-dark bg-primary-dark">
      <a class="navbar-brand mt-2" [routerLink]="'/home'">
        <!--<img src="https://s3.amazonaws.ng.mycommunity-system/bc-logo-rounded.png"
             width="60" height="60" class="d-inline-block align-middle" alt=""
        />-->
        <!--          <h1 class="navbar-text text-light pl-2">My Community</h1>-->
        <h1 class="">My Community</h1>
        <h6 class=""><i>building strong communities</i></h6>
      </a>
      <ul class="nav navbar-nav navbar-right">
        <!-- <li *ngIf="!isLoggedIn else showLoggedInNavs" class="nav-item d-flex flex-row justify-content-center align-items-center">
           <div class="nav-button nav-button-white-text mr-2">
             <a class="nav-link btn-link mr-2" routerLink="/signup">Sign up</a>
           </div>
           <div>
             <a class="btn btn-block btn-secondary" routerLinkActive="current" routerLink="/login">Login</a>
           </div>
         </li>
     <ng-template #showLoggedInNavs>
           <li class="nav-item  d-flex flex-row align-items-center">
             <a mat-flat-button class="nav-btn mr-2" routerLink="/user/{{userData.id}}">
 &lt;!&ndash;              <fa-icon [icon]="faHouseUser"></fa-icon>&ndash;&gt;
               Home
             </a>
             <a mat-flat-button class="nav-btn mr-2" routerLink="/account">
 &lt;!&ndash;              <fa-icon [icon]="faCog"></fa-icon>&ndash;&gt;
               Account
             </a>
             <div class="nav-button nav-button-white-text mr-2">
               <a class="nav-link btn-link" routerLinkActive="logout" (click)="logout()" routerLink="/">Logout</a>
             </div>
           </li>
         </ng-template>-->
      </ul>
    </nav>
  </div>
  <div class="col-sm-1"></div>
</div>
<div class="row bg-primary">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <nav class="navbar navbar-expand navbar-dark custom-bg-primary">
      <ul class="navbar-nav mr-auto ml-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/home">Home <span
            class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/about">About <span
            class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/communities">Communities <span
            class="sr-only">(current)</span></a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="col-sm-1"></div>
</div>





<!--
<div class="row bg-primary-dark">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <nav class="navbar navbar-dark bg-primary-dark">
        <a class="navbar-brand mt-2" [routerLink]="'/home'">
          &lt;!&ndash;<img src="https://s3.amazonaws.ng.mycommunity-system/bc-logo-rounded.png"
               width="60" height="60" class="d-inline-block align-middle" alt=""
          />&ndash;&gt;
&lt;!&ndash;          <h1 class="navbar-text text-light pl-2">My Community</h1>&ndash;&gt;
          <h1 class="">My Community</h1>
          <h6 class=""><i>building strong communities</i></h6>
        </a>
      <div>
        <a class="btn btn-block btn-secondary" routerLinkActive="current" routerLink="/signup">
        <span class="text-white text-lg-center">Sign up</span>
        </a>
      </div>
    </nav>
  </div>
  <div class="col-sm-1"></div>
</div>
<div class="row bg-primary">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <nav class="navbar navbar-expand-sm navbar-dark custom-bg-primary">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/home">Home <span
              class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/about">About <span
              class="sr-only">(current)</span></a>
          </li>
          &lt;!&ndash;<li class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/community">Community <span
              class="sr-only">(current)</span></a>
          </li>&ndash;&gt;
          &lt;!&ndash;<li class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/directory">Directory <span
              class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/articles">Articles <span
              class="sr-only">(current)</span></a>
          </li>&ndash;&gt;
          &lt;!&ndash;<li class="nav-item">
            <a class="nav-link" routerLinkActive="active-link" routerLink="/news">News <span
              class="sr-only">(current)</span></a>
          </li>&ndash;&gt;
          &lt;!&ndash;<li class="nav-item">
            <a class="nav-link" routerLinkActive="current" routerLink="/advertise">Advertise</a>
          </li>&ndash;&gt;
          &lt;!&ndash;<li class="nav-item">
            <a class="nav-link" routerLinkActive="current" routerLink="/signup">Sign up</a>
          </li>&ndash;&gt;
        </ul>
        <ul class="nav navbar-nav navbar-right">

        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li *ngIf="!isLoggedIn" class="nav-item">
            <a class="btn btn-block btn-secondary" routerLinkActive="current" routerLink="/login">Login</a>
          </li>
          <li *ngIf="isLoggedIn" class="nav-item">
            <a class="btn btn-block nav-btn-yellow" routerLinkActive="current" routerLink="/user">Account</a>
          </li>
          <li *ngIf="isLoggedIn" class="nav-item">
            <a class="nav-link btn btn-block" routerLinkActive="current" (click)="logout()">Logout</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div class="col-sm-1"></div>
</div>-->

