import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../service/company.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-email-enquiry',
  templateUrl: './email-enquiry.component.html',
  styleUrls: ['./email-enquiry.component.scss']
})
export class EmailEnquiryComponent implements OnInit {

  form = new FormGroup(
    {
      id: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      telephone: new FormControl('', []),
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required])
    }
  );
  companyId;
  isSending = false;
  constructor(private companyService: CompanyService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.companyId = this.activatedRoute.snapshot.params.id;
    this.id.setValue(this.companyId);
  }

  get name(): AbstractControl{
    return this.form.get('name');
  }

  get email(): AbstractControl{
    return this.form.get('email');
  }

  get telephone(): AbstractControl{
    return this.form.get('telephone');
  }

  get subject(): AbstractControl{
    return this.form.get('subject');
  }

  get message(): AbstractControl{
    return this.form.get('message');
  }

  get id(): AbstractControl{
    return this.form.get('id');
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    }
    this.isSending = true;
    this.companyService.sendEmailEnquiry(this.form.value, this.companyId)
      .subscribe(() => {
        this.isSending = false;
        this.clearForm();
      }, error => {
        this.isSending = false;
      });
  }

  clearForm(){
    this.form.get('name').setValue('');
    this.form.get('email').setValue('');
    this.form.get('telephone').setValue('');
    this.form.get('subject').setValue('');
    this.form.get('message').setValue('');
  }
}
