<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">

    <div class="row">
      <div class="col-sm-12 col-md-2">
        <div class="left-nav">


          <!--<div class="row">
            <div class="col-sm-4 col-md-12">
              <div>
                <fa-icon [icon]="faHome"></fa-icon>
                <a routerLink="./" class="ml-2">Home</a>
              </div>
            </div>
            <div class="col-sm-4 col-md-12">
              <div class="mt-2">
                <fa-icon [icon]="faHeart" [classes]="['text-danger']"></fa-icon>
                <a routerLink="dating" class="ml-2">Dating</a>
              </div>
            </div>
            <div class="col-sm-4 col-md-12">
              <div class="mt-2">
                <fa-icon [icon]="faUserCog"></fa-icon>
                &lt;!&ndash;          <a [routerLink]="'/user/setting'" class="ml-2">Settings</a>&ndash;&gt;
                <a [routerLink]="'setting'" class="ml-2">Settings</a>
              </div>
            </div>
          </div>-->




          <div>
            <fa-icon [icon]="faHome"></fa-icon>
            <a routerLink="./" class="ml-2">Home</a>
          </div>
<!--          <div class="mt-2">
            <fa-icon [icon]="faHeart" [classes]="['text-danger']"></fa-icon>
            <a routerLink="dating" class="ml-2">Dating</a>
          </div>-->
          <div class="mt-2">
            <fa-icon [icon]="faUserCog"></fa-icon>
            <!--          <a [routerLink]="'/user/setting'" class="ml-2">Settings</a>-->
            <a [routerLink]="'setting'" class="ml-2">Settings</a>
          </div>
          <div class="mt-2">
            <fa-icon [icon]="faUser"></fa-icon>
            <!--          <a [routerLink]="'/user/view/'+userData?.id" class="ml-2">My Profile</a>-->
            <a [routerLink]="'profile/'+userData?.id" class="ml-2">My Profile</a>
          </div>
          <div *ngIf="isUserAdminRole()" class="mt-2">
            <fa-icon [icon]="faNewspaper"></fa-icon>
            <a routerLink="articles" class="ml-2">Articles</a>
          </div>
          <div class="mt-2">
            <fa-icon [icon]="faAd"></fa-icon>
            <a routerLink="directory" class="ml-2">Directory</a>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-10">
        <div class="w-100">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>






    <div class="d-flex flex-row">
      <!--left side-->
     <!-- <div class="left-nav">
        <div>
          <fa-icon [icon]="faHome"></fa-icon>
          <a routerLink="./" class="ml-2">Home</a>
        </div>
        <div class="mt-2">
          <fa-icon [icon]="faHeart" [classes]="['text-danger']"></fa-icon>
          <a routerLink="dating" class="ml-2">Dating</a>
        </div>
        <div class="mt-2">
          <fa-icon [icon]="faUserCog"></fa-icon>
          &lt;!&ndash;          <a [routerLink]="'/user/setting'" class="ml-2">Settings</a>&ndash;&gt;
          <a [routerLink]="'setting'" class="ml-2">Settings</a>
        </div>
        <div class="mt-2">
          <fa-icon [icon]="faUser"></fa-icon>
&lt;!&ndash;          <a [routerLink]="'/user/view/'+userData?.id" class="ml-2">My Profile</a>&ndash;&gt;
          <a [routerLink]="'profile/'+userData?.id" class="ml-2">My Profile</a>
        </div>
        <div *ngIf="isUserAdminRole()" class="mt-2">
          <fa-icon [icon]="faNewspaper"></fa-icon>
          <a routerLink="articles" class="ml-2">Articles</a>
        </div>
        <div class="mt-2">
          <fa-icon [icon]="faAd"></fa-icon>
          <a routerLink="directory" class="ml-2">Directory</a>
        </div>
      </div>-->

      <!--main column-->
      <!--<div class="w-100">
        <router-outlet></router-outlet>
      </div>-->
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
