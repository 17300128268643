<div>



  <!--input area-->
  <div *ngIf="userData?.id && !isNavSelected(allForumType)" class="mt-3">
    <div class="card p-4">
      <!--testimony-->
      <div>
        <div class="form-group">
          <div *ngIf="isNavSelected(fellowshipForumType)"><label for="message">Post a message to fellowship
            forum</label></div>
          <div *ngIf="isNavSelected(prayerForumType)"><label for="message">Post a prayer or prayer request</label></div>
          <div *ngIf="isNavSelected(testimonyForumType)"><label for="message">Share a testimony</label></div>
          <textarea id="message" class="form-control" rows="5" [(ngModel)]="fellowshipMessage"></textarea>
        </div>
        <div class="form-group">
          <div><span>Add a picture</span></div>
          <input type="file" class="form-control-lg pl-0"
                 (change)="onChangeFellowshipPhoto($event)">
        </div>
        <div *ngIf="errorMsg" class="my-3">
          <span class="text-danger">{{errorMsg}}</span>
        </div>
        <div class="d-flex flex-row">
          <button (click)="submit()" class="btn btn-primary">
            Post <app-spinner *ngIf="isSaving" [spinner-options]="{isSpinning: true}"></app-spinner>
          </button>
          <div *ngIf="hasUserOrgs()" class="d-flex flex-row">
            <div class="px-3">as</div>
            <form [formGroup]="postAsFormGp" class="">
              <select class="form-control" formControlName="org">
                <option [value]="'-1'" [selected]="true">{{userData.name}}</option>
                <option *ngFor="let org of userOrgs" [value]="org.id">{{org.name}}</option>
              </select>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>


  <hr class="border-secondary h-25">

  <!--navigation area-->
  <div class="d-flex justify-content-center bg-primary-light h-100">
    <!--<div
      [ngClass]="{'text-white': true,
        'h-100': true,
        'mw-100': true,
        'bg-primary': isNavSelected(allForumType),
        'p-3': true,
        'navigation-item': true,
        'navigation-item-active': isNavSelected(allForumType)}"
      (click)="onNavClick(allForumType)">All
    </div>-->
    <div
      [ngClass]="{'text-white': true,
        'h-100': true,
        'mw-100': true,
        'bg-primary': isNavSelected(fellowshipForumType),
        'p-3': true,
        'navigation-item': true,
        'navigation-item-active': isNavSelected(fellowshipForumType)}"
      (click)="onNavClick(fellowshipForumType)">Fellowship
    </div>
    <div
      [ngClass]="{'text-white': true,
        'h-100': true,
        'mw-100': true,
        'bg-primary': isNavSelected(prayerForumType),
        'p-3': true,
        'navigation-item': true,
        'navigation-item-active': isNavSelected(prayerForumType)}"
      (click)="onNavClick(prayerForumType)">Prayers
    </div>
    <div
      [ngClass]="{'text-white': true,
        'h-100': true,
        'mw-100': true,
        'bg-primary': isNavSelected(testimonyForumType),
        'p-3': true,
        'navigation-item': true,
        'navigation-item-active': isNavSelected(testimonyForumType)}"
      (click)="onNavClick(testimonyForumType)">Testimonies
    </div>

  </div>


  <!--content area-->
  <div class="row">

    <!--community content-->
    <div class="col-sm-12 col-md-8">

      <!--fellowship-->
      <div class="mt-5">
        <div *ngFor="let fellowshipItem of fellowshipListItems" class="media mt-3 pr-1">
          <img *ngIf="!fellowshipItem.organisationName; else orgImg" class="mr-3 rounded-circle"
               [src]="fellowshipItem.userPicUrl"
               alt="image" width="40" height="40">
          <ng-template #orgImg>
            <img *ngIf="fellowshipItem.organisationLogo" class="mr-3 rounded-circle"
                 [src]="fellowshipItem.organisationLogo"
                 alt="image" width="40" height="40">
          </ng-template>


          <div class="media-body">
            <a *ngIf="!fellowshipItem.organisationName; else orgName" [routerLink]="'/user/view/'+fellowshipItem.userId">
              <h5 class="mt-0">{{fellowshipItem.userName}}</h5>
            </a>
            <ng-template #orgName>
              <a [routerLink]="'/page/' + fellowshipItem.organisationId + '/'">
                <h5 class="mt-0">{{fellowshipItem.organisationName}}</h5>
              </a>
            </ng-template>

            <span>{{fellowshipItem.datePosted  | amFromUnix | amDateFormat:'LL'}}</span>
            <p class="mt-2" [innerHTML]="textFormatService.autoLinkText(fellowshipItem.body) | nl2br: true"></p>
            <img *ngIf="fellowshipItem.picUrl?.length>1"
                 class="img-fluid rounded mx-auto d-block shadow-lg custom-img-fluid"
                 [src]="fellowshipItem.picUrl"
                 alt="comment picture">

            <!-- action panel-->
            <div class="d-flex flex-row rounded border border-dark mt-2">
              <button class="btn btn-link" (click)="setActiveFellowshipItem(fellowshipItem.id)">
                <span class="text-dark">comment</span>
              </button>
            </div>
            <!--reply box-->
            <div class="d-flex flex-column mt-2" *ngIf="showReplyBox(fellowshipItem.id)">
              <textarea class="form-control" [(ngModel)]="comment"></textarea>
              <button class="btn btn-secondary mt-2" (click)="submitReply(fellowshipItem)">
                <fa-icon *ngIf="isSavingReply" class="d-flex justify-content-center" [icon]="faSpinner"
                         [spin]="isSavingReply" class="text-primary"></fa-icon>
                Comment
              </button>
            </div>


            <!--replies-->
            <div class="d-flex flex-column ml-5 mt-2" *ngFor="let reply of fellowshipItem.replies">
              <!--user image and name-->
              <div class="d-flex flex-row">
                <div>
                  <img class="mr-3 rounded-circle"
                       [src]="reply.userImageUrl"
                       alt="image" width="40" height="40">
                </div>
                <div>
                  <a [routerLink]="'/user/view/'+reply.userId">
                    <h5 class="mt-0">{{reply.userName}}</h5>
                  </a>
                  <span>{{reply.datePosted  | amFromUnix | amDateFormat:'LL'}}</span>
                </div>
              </div>

              <!--body-->
              <div>
                <p class="mt-2" [innerHTML]="reply.comment | nl2br: true"></p>
              </div>
            </div>
          </div>
        </div>
        <div class=" d-flex justify-content-center my-5">
          <button class="btn btn-lg btn-secondary" (click)="nextFellowshipItemsPage()">More</button>
        </div>
      </div>
    </div>

    <!--right side on large. Bottom on phone-->
    <div class="col-sm-12 col-md-4">
      <!--events-->
      <!--<div>events</div>
      <div>announcements</div>
      <div>organisations</div>-->
    </div>
  </div>

</div>



