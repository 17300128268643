import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../environments/environment.prod";
import {Store} from "@ngrx/store";
import {AppState} from "../app.state";
import {SetAuthenticationToken} from "../component/user/user.action";

@Injectable({
  providedIn: 'root'
})
export class JwtTokenInterceptorService implements HttpInterceptor {

  constructor(private store: Store<AppState>,
              private cookieService: CookieService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //inject the token in the header here for every request
    let token: string;
    this.store.pipe(take(1)).subscribe(state =>  {
      token = state.user.authentication.token;
    });

    if(token){
      req = req.clone({
        headers: req.headers.set('X-Auth-Token', token)
      });
    }

    return next.handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const jwtToken = event.headers.get('token');
            if (jwtToken) {
              // this.ngRedux.dispatch(AuthenticationActionService.getAuthenticationTokenAction(jwtToken));
              this.store.dispatch(new SetAuthenticationToken({token: jwtToken}));
              this.cookieService.set(
                environment.jwtTokenCookieName,
                jwtToken,
                1
              )
            }
          }
          return event;
        })
      )
  }


}
