import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompanyRoutingModule } from './company-routing.module';
import { CompanyAddComponent } from './company-add/company-add.component';
import {ReactiveFormsModule} from "@angular/forms";
import { CompanyEditComponent } from './company-edit/company-edit.component';
import {CorporateViewComponent} from "./corporate-view/corporate-view.component";
import {Nl2BrPipeModule} from "nl2br-pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { EmailEnquiryComponent } from './email-enquiry/email-enquiry.component';
import { OrganisationBodyComponent } from './organisation-body/organisation-body.component';
import { OrganisationVodcastComponent } from './organisation-vodcast/organisation-vodcast.component';
import {SpinnerModule} from "../spinner/spinner.module";
import {MomentModule} from "ngx-moment";


@NgModule({
  declarations: [CompanyAddComponent, CompanyEditComponent, CorporateViewComponent, EmailEnquiryComponent,
    OrganisationBodyComponent,
    OrganisationVodcastComponent],
    imports: [
        CommonModule,
        CompanyRoutingModule,
        ReactiveFormsModule,
        Nl2BrPipeModule,
        FontAwesomeModule,
        SpinnerModule,
        MomentModule
    ],
  exports: [CompanyAddComponent]
})
export class CompanyModule { }
