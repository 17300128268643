<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row mt-3">
      <div class="col-sm-12">
        <a [routerLink]="'/user'">
          <fa-icon [icon] ="faArrowLeft"></fa-icon><span class="ml-5">back to accounts page</span>
        </a>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-sm-12">
        <a [routerLink]="'/user/setting/general'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">General profile</span>
        </a>
        <a [routerLink]="'/user/setting/dating'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Dating profile</span>
        </a>
        <a [routerLink]="'/user/setting/pictures'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Pictures</span>
        </a>
        <a [routerLink]="'/user/setting/password'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Password</span>
        </a>
<!--        <button class="btn btn-lg btn-secondary btn-block mt-5"><span class="text-white">Singles profile</span></button>-->
        <a [routerLink]="'/user/setting/notifications'" class="btn btn-lg btn-secondary btn-block mt-5">
          <span class="text-white">Notifications</span>
        </a>
        <a [routerLink]="'/user/setting/deactivate'" class="btn btn-lg btn-secondary btn-block mt-5">
          Deactivate
        </a>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>
