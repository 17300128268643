import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {Subscription} from "rxjs";
import {UserService} from "../../../service/user/user.service";
import {LocationService} from "../../../service/location/location.service";
import {LocationModel} from "../../../model/location.model";
import {Router} from "@angular/router";
import {SetUserAction} from "../user.action";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.scss']
})
export class UserProfileEditComponent implements OnInit, OnDestroy {
  @Input('UserProfileEdit')
  userData: UserDataModel;
  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    about: new FormControl('', []),
    locationId: new FormControl('', [])
  });
  userSub: Subscription;
  locations: LocationModel[];
  faArrowLeft: any;

  constructor(private store: Store<AppState>,
              private userService: UserService,
              private locationService: LocationService,
              private router: Router
  ) {
    this.userSub = store.select('user').subscribe((user: UserModel) => {
      this.userData = user.user;
      this.name = this.userData.name;
      this.about = this.userData.aboutMe;
      this.locationId = this.userData.location.id;
    });
    this.faArrowLeft = faArrowLeft;

  }

  ngOnInit() {
    this.locationService.getLocations()
      .subscribe(locations => {
        this.locations = locations;
      })
  }

  ngOnDestroy()
    :
    void {
    this.userSub.unsubscribe();
  }

  set name(name
             :
             string
  ) {
    this.form.get('name').setValue(name);
  }

  get name() {
    return this.form.get('name').value;
  }

  set about(about: string) {
    this.form.get('about').setValue(about);
  }

  get about() {
    return this.form.get('about').value;
  }

  get locationId() {
    return +this.form.get('locationId').value;
  }

  set locationId(id: number) {
    this.form.get('locationId').setValue(id);
  }

  submit() {
    const data = {
      name: this.name,
      about: this.about,
      locationId: this.locationId
    }
    this.userService.updateUserProfile(this.userData.id, data)
      .subscribe((user: UserDataModel) => {
        this.store.dispatch(new SetUserAction({user: user}));
        this.router.navigate(['/user'])
      });
  }

}
