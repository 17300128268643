import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ArticleComponent} from "./article/article.component";
import {ArticlesComponent} from "./articles/articles.component";
import {ArticleAddComponent} from "./article-add/article-add.component";
import {ArticlePictureEditComponent} from "./article-picture-edit/article-picture-edit.component";


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'articles'
  },
  {
    path: 'articles',
    component: ArticlesComponent
  },
  {
    path: 'article/:id',
    component: ArticleComponent,
  },{
    path: 'article/:id/:communityId',
    component: ArticleAddComponent
  },
  {
    path: 'article/:id/picture/edit',
    component: ArticlePictureEditComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticleRoutingModule { }
