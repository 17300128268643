<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">

    <div class="row mt-3 p-3">
      <div class="col-sm-12 col-md-5 mt-5 mt-sm-5 mt-md-0">
        <form [formGroup]="form">
          <div class="form-group">
            <div class="label">Enter a user name</div>
            <div>
              <input class="form-control" type="text" formControlName="username">
            </div>
            <div *ngIf="username.touched && username.invalid" class="alert-danger"><span>Enter a user name</span></div>
          </div>
          <div class="form-group">
            <div>Select your location</div>
            <div>
              <select class="custom-select custom-select-lg mb-3"
                      formControlName="locationId">
                <option selected value="0"
                >Select your location
                </option>
                <option *ngFor="let location of locations "
                        [ngValue]="location.id"
                >{{location.name}}</option>
              </select>
              <div *ngIf="locationId.touched && locationId.invalid" class="alert-danger"><span>Select a location</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="label">Enter email address</div>
            <div>
              <input class="form-control" formControlName="email"/>
            </div>
            <div *ngIf="email.touched && email.invalid" class="alert-danger"><span>Enter a valid email address</span>
            </div>
          </div>
          <!--confirm email-->
          <div class="form-group">
            <div class="label">Confirm email address</div>
            <div>
              <input class="form-control" formControlName="confirmEmail"/>
            </div>
            <div *ngIf="confirmEmail.touched && (confirmEmail.invalid || !isEmailMatching())" class="alert-danger">
              <span>Enter a valid email address</span>
            </div>
          </div>
          <!--password-->
          <div class="form-group">
            <div class="label">Enter password</div>
            <div>
              <input class="form-control"
                     type="password"
                     formControlName="password"/>
            </div>
          </div>
          <!--confirm password-->
          <div class="form-group">
            <div class="label">Confirm password</div>
            <div>
              <input class="form-control"
                     type="password"
                     formControlName="confirmPassword"/>
            </div>
          </div>
          <div class="form-group">
            <div>
              <button class="btn btn-primary" (click)="submit()">Submit</button>
            </div>
            <div class="mt-2"><span class="text-danger">{{errorMessage}}</span></div>
          </div>

        </form>
      </div>
      <!--<div class="col-sm-12 col-md-5">
        <div><span class="display-4">Join a place of caring and sharing</span></div>
        <div><span class="text-black-50">Psalm 133:1 How good and pleasant it is when brothers live together in unity!</span></div>
        <div class="mt-4">
          <div>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"></fa-icon>
            <span class="ml-3">Enjoy uplifting fellowship</span>
          </div>
          <div>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"></fa-icon>
            <span class="ml-3">Pray together</span>
          </div>
          <div>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"></fa-icon>
            <span class="ml-3">Testify of God's goodness</span>
          </div>
          <div>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"></fa-icon>
            <span class="ml-3">Promote your enterprise in the marketplace</span>
          </div>
          <div>
            <fa-icon class="text-success" [icon]="faCheck" size="2x"></fa-icon>
            <span class="ml-3">List your organisation in the Christian directory</span>
          </div>

        </div>
      </div>-->
    </div>


  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>

