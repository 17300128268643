<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">

    <div class="row p-5 ">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <img class="card-img-top rounded-circle shadow-lg"
                 alt="header image"
                 [src]="userData?.userHeaderPic"
                 data-holder-rendered="true"
                 style="height: 225px;
                 width: 100%;
                 display: block;"/>
          </div>
          <div class="col-sm-12 col-md-8">
            <div class="row">
              <div class="col-xs-12">
                <div class="pl-3">
                  <h5 class="mt-0">{{userData?.name}}</h5>
                  <div>
                    <p>Member since: <span>{{userData?.regDate | amFromUnix | amDateFormat:'LL'}}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr/>


    <ul class="nav justify-content-center">
      <li class="nav-item">
        <button class="btn btn-secondary d-inline-block" disabled>Posts</button>
        <button class="btn btn-secondary d-inline-block ml-3" disabled>Testimonies</button>
        <button class="btn btn-secondary d-inline-block ml-3" disabled>Prayers</button>
      </li>
    </ul>

    <hr/>

  </div>
  <div class="col-sm-1"></div>
</div>




