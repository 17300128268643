<div class="row mt-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <p>
      Here you can download forms, documents and access information made available by the association.
    </p>

    <p>
      <b>
        <a href="https://s3.amazonaws.com/mycommunity.ng/residents-registration-form.docx">Download the residents registration form </a>
      </b>
    </p>
    <p>
      <b>
        <a href="https://s3.amazonaws.com/mycommunity.ng/security-flier-pdf.pdf" target="_blank">Security initiative information flier</a>
      </b>
    </p>
  </div>
  <div class="col-md-1"></div>
</div>
