<div class="row px-xs-5">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">

    <div class="row px-sm-3">
      <div class="col-sm-12">
        <div class=" d-flex flex-column justify-content-center mt-5">
          <div class="row mt-3">
            <div *ngFor="let picture of userViewableData?.pictures" class="mb-3 d-flex flex-column justify-content-between col-sm-12 col-md-4">
              <img  class="img-fluid rounded mx-auto shadow-lg custom-img-fluid"
                    [src]="picture.url"
                    alt="pic">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
