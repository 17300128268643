import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {ProductService} from "../../../service/product/product.service";
import {ProductCategoryModel} from "../../../model/product-category.model";
import {CompanyService} from "../../company/service/company.service";
import {CompanyModel} from "../../company/model/company.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {ProductModel} from "../../../model/product.model";

enum ModeEnum{
  ADD,
  EDIT
}

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit, OnDestroy {
  form = new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      category: new FormControl('-1', [Validators.required, Validators.min(1)]),
      company: new FormControl('-1', [Validators.required, Validators.min(1)])
    }
  );

  productCategories$: Observable<ProductCategoryModel[]>;
  userId: number;
  isUserLoggedIn: boolean = true;
  companies$: Observable<CompanyModel[]>;
  userSub: Subscription;
  user: UserDataModel;
  addMode: ModeEnum = ModeEnum.ADD;
  editMode: ModeEnum = ModeEnum.EDIT;
  activeMode: ModeEnum = this.addMode;
  productId: number;


  constructor(private store: Store<AppState>,
              private productService: ProductService,
              private companyService: CompanyService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.userSub = this.store.select('user')
      .subscribe((user: UserModel) => {
        this.user = user.user;
      })

    if(this.activatedRoute.snapshot.params.mode === 'add'){
      this.activeMode = this.addMode;
    }else{
      this.activeMode = this.editMode;
      this.productId = this.activatedRoute.snapshot.params.id
    }


  }

  ngOnInit() {
    this.productCategories$ = this.productService.getProductCategories();
    this.companies$ = this.companyService.getCompanies(this.user.id);
    if (this.editMode) {
      //get product
      this.getProduct(this.productId);
    }
  }

  ngOnDestroy() {

  }

  get descriptionCtl(): AbstractControl {
    return this.form.get('description');
  }

  get nameCtl(): AbstractControl {
    return this.form.get('name');
  }

  get categoryCtl (): AbstractControl {
    return this.form.get('category');
  }

  get companyCtl (): AbstractControl {
    return this.form.get('company');
  }

  get name (): string {
    return this.form.get('name').value;
  }

  set name(name: string) {
    this.form.get('name').setValue(name);
  }

  get description (): string {
    return this.form.get('description').value;
  }

  set description(desc: string){
    this.form.get('description').setValue(desc);
  }


  get companyId() : number {
    return +this.form.get('company').value;
  }

  set companyId(id: number){
    this.form.get('company').setValue(id);
  }

  get categoryId() : number {
    return +this.form.get('category').value;
  }

  set categoryId(id: number) {
    this.form.get('category').setValue(id);
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const data = {
        name: this.name,
        description: this.description,
        companyId: this.companyId,
        productCategoryId: this.categoryId
      };
      this.productService.postProduct(data)
        .subscribe(response => {
            this.activeMode = this.editMode;
        },
          error => {

          });
    }
  }


  edit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const data = {
        id: this.productId,
        name: this.name,
        description: this.description,
        companyId: this.companyId,
        productCategoryId: this.categoryId
      };
      this.productService.editProduct(data)
        .subscribe(response => {
            this.router.navigate(['/user']);
        },
          error => {

          });
    }
  }

  isAddMode(){
    return this.activeMode === this.addMode;
  }

  isEditMode(){
    return this.activeMode === this.editMode;
  }

  getProduct(id: number) {
    this.productService.getProduct(id)
      .subscribe((product: ProductModel) => {
        this.setForm(product);
      })
  }

  setForm(product: ProductModel){
    this.description = product.description;
    this.name = product.name;
    this.categoryId = product.productCategory.id;
    this.companyId = product.companyId;
  }
}
