import {AuthenticationModel} from "../model/userModel";

export class AuthenticationTransformer {
  static convertJsonToAuthentication(json: any): AuthenticationModel{
    return {
      id: json.id,
      role: json.role,
      token: json.token
    }
  }
}
