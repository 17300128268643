import { Component, OnInit } from '@angular/core';
import {ArticleService} from "../../../service/article/article.service";
import {ArticleModel} from "../../../model/article.model";

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  articles: ArticleModel[] = [];
  topArticle: ArticleModel;
  pageNumber = 0;
  constructor(private articleService: ArticleService) {

  }

  ngOnInit() {
    this.getNews(this.pageNumber);
  }

  getNews(pageNumber: number) {
    this.articleService.getArticles(pageNumber)
      .subscribe((newsList: ArticleModel[]) => {
        if (!this.topArticle) {
          this.topArticle = newsList.shift();
        }
        this.articles = this.articles.concat(newsList);
      })
  }

  more() {
    this.getNews(++this.pageNumber)
  }
}
