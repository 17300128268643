import {Component, Input, OnInit} from '@angular/core';
import {faArrowLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {AppState} from "../../../app.state";
import {Store} from "@ngrx/store";
import {UserDataModel, UserModel, UserPicture} from "../../../model/userModel";
import {Subscription} from "rxjs";
import {UserService} from "../../../service/user/user.service";
import {SetUserAction} from "../user.action";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-user-gallery',
  templateUrl: './user-gallery.component.html',
  styleUrls: ['./user-gallery.component.scss']
})
export class UserGalleryComponent implements OnInit {
  faArrowLeft: any;
  faSpinner: any;
  userViewableData: UserDataModel;
  userViewableId: number;

  constructor(private store: Store<AppState>,
              private userService: UserService,
              private activatedRoute: ActivatedRoute) {
    this.faArrowLeft = faArrowLeft;
    this.faSpinner = faSpinner;
   /* this.userSub = this.store.select('user').subscribe((user: UserModel) => {
      if (user.user) {
        this.userData = user.user;
      }
    })*/
  }

  ngOnInit() {
    this.userViewableId = this.activatedRoute.snapshot.parent.params.id;
    this.getUser(this.userViewableId);
  }

  getUser(userId: number) {
    this.userService.getUser(userId).subscribe((userViewable: UserDataModel) => {
      this.userViewableData = userViewable;
      if (!this.userViewableData.userHeaderPic || this.userViewableData.userHeaderPic.length == 0) {
        this.userViewableData.userHeaderPic = this.userService.userNoPic;
      }
    })
  }

}
