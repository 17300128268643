import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SignupComponent} from "./signup/signup.component";
import {EmailVerifyComponent} from "./email-verify/email-verify.component";


const routes: Routes = [
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'email-verify/:id',
    component: EmailVerifyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule { }
