<div style="width: 500px">
  <h1>Add article content for</h1>
  <h2>{{data.headline}}</h2>
  <form [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea rows="20" cols="100" matInput placeholder="Enter the description" formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Image url</mat-label>
      <input type="text" matInput placeholder="Enter the image url" formControlName="imageUrl"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Video url</mat-label>
      <input type="text" matInput placeholder="Enter the video url" formControlName="videoUrl"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Content type (1=text;2=pic;3=video)</mat-label>
      <input type="number" matInput placeholder="Enter the content type" formControlName="contentTypeId"/>
    </mat-form-field>


      <button *ngIf="data.mode === createMode; else edit" mat-raised-button color="primary" (click)="submit()">Submit</button>
    <ng-template #edit>
      <button mat-raised-button color="primary" (click)="editContent()">Edit</button>
    </ng-template>
    <button mat-raised-button color="primary"
            class="ml-3"
            (click)="cancel()">Cancel</button>
  </form>
</div>

