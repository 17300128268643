<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row my-3 d-flex justify-content-center">
      <div class="col-12-sm">
        <div class="jumbotron">
          <h1 class="display-4">Advertise on British Christian</h1>
          <p class="lead">
            We have strategies that will help you achieve your aspirations. Find out what suits you below and give us a call.
          </p>
        </div>
      </div>
    </div>
    <div class="row my-3 px-sm-3">
      <div class="col-md-6">
        <h5 class="text-secondary">
          Brand promotion
        </h5>
        <p>
          If you offer great service then you deserve to become a household name.
        </p>
        <p>
          We have several spots on our web platform that will maximise your visibility. Keep yourself on the minds of
          your potential clients by buying one of the limited number of placements on this web platform. We have a web
          traffic that is growing everyday and you can share in that growth to increase the awareness of your organisation.
        </p>
        <p>
          Price: <strong> &pound;100</strong> per placement per week. Contact us below for details
        </p>
      </div>
      <div class="col-md-6">
        <h5 class="text-secondary">Christian business directory</h5>
        <p>
          We are at the forefront of web technology and are dedicated to deploying it to increase the wealth of our believers
        </p>
        <p>
          Many Christian inventors, creators, artists, writers and ministers as well as organisations suffer from one
          common hindrance, <strong>visibility</strong>
        </p>
        <p>The Christian business directory is focused on advertising the works of members of the Kingdom of God with one aim; to
        help you succeed in your endeavour. The package includes</p>
        <ul>
          <li>Create your web page with secure contact form</li>
          <li>Listing in our searchable directory</li>
          <li>Participation in a vodcast describing your activities</li>
          <li>Inclusion in at least one distribution of our E-Newsletter</li>
        </ul>
        <p>
          The cost is <strong> &pound;60</strong> per year. Minimum term is 6 months. Contact us below for details
        </p>
      </div>
    </div>
    <div class="row my-3 px-sm-3">
      <div class="col-md-6">
        <h5 class="text-secondary">
          Announcements
        </h5>
        <p>
          Take advantage of our widely distributed newsletter to reach a large audience with your announcement.
        </p>
        <p>
          Our E-newsletter circulates to 2000 plus verified subscribers. We can distribute your community, weddings,
          obituaries, christenings, dedications and remembrance announcements. Fairs and bazaars, fund raisers, book and album launches etc.
        </p>
        <p>We will create a smart looking web page for your announcement and include it in our newsletter circulation at least once.</p>
        <p>
          Price: Only<strong> &pound;5 </strong> per month inclusion. Contact us below for details
        </p>
      </div>

      <div class="col-md-6">
        <h5 class="text-secondary">Events</h5>
        <p>
          Everybody wants to have a successful event. That can only happen if the right people know about it.
        </p>
        <p>
          Your event page will be created with your event details and we will further promote the event in our widely
          circulated E-Newsletter through our very clean email list (We get near zero email bounces) so you know your message is getting to
          real people. Our mailing list is over 2000 and growing daily.
        </p>
        <p>
          Price: Only<strong> &pound;5</strong> per month inclusion. Contact us below for details
        </p>
      </div>
    </div>

    <!--contacts-->
    <div class="row my-3 px-sm-3">
      <div class="col-md-6">
        <h5 class="text-secondary">
          Contact
        </h5>
        <p>
          Email: admin@britishchristian.com
        </p>
        <p>
          Telephone: +44 7913700626
        </p>
      </div>
    </div>

  </div>
  <div class="col-sm-1"></div>
</div>





