<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row my-3 d-flex justify-content-center">
      <div class="col-12-sm">
        <div class="jumbotron">
          <h1 class="display-4">Building strong communities</h1>
          <p class="lead">
            Welcome to my community. In the digital age access to timely information is critical to development. My community
            is therefore a platform for the effective management and distribution of information at the grassroot level. We provide services
            that enable communities to function harmoniously and effectively by leveraging digital technology.
          </p>
          <!--<hr class="my-4">
          <p class="lead">
            <a class="btn btn-primary btn-lg" routerLink="/signup" role="button">Sign up free!</a>
          </p>-->
        </div>
      </div>
    </div>
    <div class="row my-3 px-sm-3">
      <div class="col-md-4">
        <h5 class="text-secondary">
          Media services
        </h5>
        <p>
          At the heart of the 'My Community' project is the provision of media services to the grass root. We offer news services
          covering local government authorities and community development associations.
        </p>
        <p>
          Most news and print media are focused on international and national level issues with very little attention to
          the challenges and successes of local governing councils and community groups.
        </p>
        <p>Book your interview slot today and join up to take advantage of our platform to highlight your community</p>
      </div>
      <div class="col-md-4">
        <h5 class="text-secondary">
          CDA services
        </h5>
        <p>
          My community is a natural digital platform for community development associations of all sizes. We cater for estates,
          sociocultural and sociopolitical groups. We have developed digital services that assist communities to communicate
          collect and manage data.
        </p>
        <p>
          We have tailored digital solutions that empower CDAs to publish news effectively within its defined membership and
          also crucially with the outside world.
        </p>
        <p>
          Communities benefit from knowing and communicating effectively with those in charge of running the affairs of the community.
          My Community enhances access to community leaders to members of their communities.
        </p>
      </div>
      <div class="col-md-4">
        <h5 class="text-secondary">
          Smart cities
        </h5>
        <p>
          The <i>My Community</i> project allows communities to digitise its services. We have provided a directory of
          services that entrepreneurs and CDAs can leverage to expose and provide services to the community.

        </p>
        <p>
          The software specifically developed by the team can help local communities plug into the United Nations Development Programme
          smart societies agenda by digitising data collection and administration.
        </p>
      </div>
    </div>

    <!--contacts-->
    <!--<div class="row my-3 px-sm-3">
      <div class="col-md-6">
        <h5 class="text-secondary">
          Contact
        </h5>
        <p>
          Email: [coming soon]
        </p>
        <p>
          Telephone: +44 7913700626
        </p>
      </div>
    </div>-->


  </div>
  <div class="col-sm-1"></div>
</div>





