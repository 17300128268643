import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SignupComponent} from "../registration/signup/signup.component";
import {AboutComponent} from "./about/about.component";
import {AdvertiseComponent} from "./advertise/advertise.component";
import {PrivacyComponent} from "./privacy/privacy.component";


const routes: Routes = [
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'advertise',
    component: AdvertiseComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutRoutingModule { }
