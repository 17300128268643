import {FellowshipModel} from "../model/fellowship.model";
import {FellowshipreplyModel} from "../model/fellowshipreply.model";

export class FellowshipTransformer {
  static transformFromJsonToFellowship(json: any): FellowshipModel {
    return {
      id: json.id,
      body: json.body,
      userName: json.user.name,
      userId: json.user.id,
      userPicUrl: json.user.picUrl,
      pictureId: json.picture ? json.picture.id : null,
      picUrl: json.picture ? json.picture.url : null,
      datePosted: json.datePosted,
      replies: json.fellowshipReplyList ? FellowshipTransformer.transformFromJsonToFellowshipReplyArray(json.fellowshipReplyList) : null,
      organisationName: json.company ? json.company.name : null,
      organisationId: json.company ? json.company.id : null,
      organisationLogo: json.company ? json.company.logo : null
    }
  }

  static transformFromJsonToFellowshipReply(json: any): FellowshipreplyModel {
    return {
      id: json.id,
      userId: json.userId,
      comment: json.comment,
      userImageUrl: json.user.picUrl,
      userName: json.user.name,
      datePosted: json.datePosted
    }
  }

  static transformFromJsonToFellowshipReplyArray(jsonArr: any[]): FellowshipreplyModel[] {
    let arr: FellowshipreplyModel[] = [];
    for (let item of jsonArr) {
      arr.push(FellowshipTransformer.transformFromJsonToFellowshipReply(item))
    }
    return arr;
  }

  static transformFromJsonToFellowshipModelArray(jsonArr: any[]): FellowshipModel[] {
    let arr: FellowshipModel[] = [];
    for (let item of jsonArr) {
      arr.push(FellowshipTransformer.transformFromJsonToFellowship(item))
    }
    return arr;
  }
}
