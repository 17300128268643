import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";



@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [SpinnerComponent]

})
export class SpinnerModule { }
