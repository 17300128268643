import { Component, OnInit } from '@angular/core';
import {ArticleModel} from "../../../model/article.model";
import {ArticleService} from "../../../service/article/article.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-community-news',
  templateUrl: './community-news.component.html',
  styleUrls: ['./community-news.component.scss']
})
export class CommunityNewsComponent implements OnInit {

  articles: ArticleModel[];
  constructor(private articleService: ArticleService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    console.log(this.activatedRoute.snapshot.parent.params.id);
    this.getCommunityArticles(this.activatedRoute.snapshot.parent.params.id);
  }


  getCommunityArticles(communityId: number){
    this.articleService.getAllArticlesByCommunity(communityId)
      .subscribe(value => {
        this.articles = value;
        console.log(this.articles);
      })

  }

}
