<div class="row px-xs-5">
  <div class="col-sm-1"></div>
  <div class="col-sm-12 col-md-10">
    <div>
      <!--headline news or article-->
      <div class="row py-5">
        <!--<div *ngIf="articles[0]?.displayPic" class="col-sm-12 col-md-4">
          <a [routerLink]="'/article/'+articles[0]?.id">
            <img *ngIf="articles[0]?.displayPic" class="card-img-top"
                 data-src="holder.js/100px225?theme=thumb&amp;bg=55595c&amp;fg=eceeef&amp;text=Thumbnail"
                 alt="header image"
                 [src]="articles[0]?.displayPic"
                 data-holder-rendered="true"
                 style="height: 225px;
                 width: 100%;
                 display: block;">
          </a>
        </div>-->
        <div class="col-sm-12 col-md-8">
          <a [routerLink]="'/article/'+articles[0]?.id" class="display-4">{{articles[0]?.headline}}</a>
        </div>
      </div>
      <hr/>

      <div class="row">
        <div class="col-sm-12 col-md-9">
          <!--central mid section-->
          <div><h6>Recent articles</h6></div>
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <div class="card mb-4 box-shadow border-0">
                <!--<a *ngIf="articles[1]?.displayPic" [routerLink]="'/article/'+articles[1]?.id">
                  <img class="card-img-top custom-img-fluid shadow"
                       alt="article pic"
                       [src]="articles[1]?.displayPic"
                  >
                </a>-->
                <div class="card-body pl-0 pr-0 pt-2 pb-0">
                  <a [routerLink]="'/article/'+articles[1]?.id"><h5
                    class="card-title">{{articles[1]?.headline}}</h5></a>
                </div>
              </div>

            </div>
            <div class="col-sm-12 col-md-4">
              <div class="card mb-4 box-shadow border-0">
                <!--<a [routerLink]="'/article/'+articles[2]?.id">
                  <img class="card-img-top custom-img-fluid shadow"
                       alt="article pic"
                       [src]="articles[2]?.displayPic"
                  >
                </a>-->
                <div class="card-body pl-0 pr-0 pt-2 pb-0">
                  <a [routerLink]="'/article/'+articles[2]?.id"><h5
                    class="card-title">{{articles[2]?.headline}}</h5></a>
                </div>
              </div>

            </div>
            <div class="col-sm-12 col-md-4">
              <div class="card mb-4 box-shadow border-0">
                <!--<a [routerLink]="'/article/'+articles[3]?.id">
                  <img class="card-img-top custom-img-fluid shadow"
                       alt="article pic"
                       [src]="articles[3]?.displayPic"
                  >
                </a>-->
                <div class="card-body pl-0 pr-0 pt-2 pb-0">
                  <a [routerLink]="'/article/'+articles[3]?.id"><h5
                    class="card-title">{{articles[3]?.headline}}</h5></a>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-3">
          <!--right side section-->
          <div class="row mt-5 mt-md-2 mx-sm-3">
            <div class="col-sm-12 d-flex justify-content-center">
              <!--<a [routerLink]="'/advertise'">
                <img  class="card-img-top custom-img-fluid shadow" src="/assets/host-advert-banner.png"/>
              </a>-->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-sm-1"></div>
</div>



