<div class="row px-xs-5">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <div class="row mt-3">
      <div class="col-sm-12">
        <a [routerLink]="'/user/setting'">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
          <span class="ml-5">back to settings page</span>
        </a>
      </div>
    </div>
    <div class="row px-sm-3">
      <div class="col-sm-12">
        <div class=" d-flex flex-column justify-content-center mt-5">
          <div class="border border-primary rounded p-4">
            <h5 class="card-title">Update general profile</h5>
            <div><span class="">Use this form to update your general profile</span></div>
          </div>

          <!--form to update the user details-->
          <form class="mt-3" [formGroup]="form">
            <div class="form-group">
              <label for="name">Name:</label>
              <input id="name" class="form-control" type="text"
              formControlName="name">
            </div>
            <div class="form-group">
              <label for="about">About me:</label>
              <textarea id="about" class="form-control" type="text"
                        rows="10"
                        formControlName="about"></textarea>
            </div>
            <div class="form-group">
              <label for="location">Location:</label>
              <select id="location" class="custom-select custom-select-lg mb-3"
                      formControlName="locationId">
                <option selected value="0">Select location</option>
                <option *ngFor="let location of locations "
                        [ngValue]="location.id"
                        [selected]="location.id === locationId"
                >{{location.name}}</option>
              </select>
            </div>
          </form>

          <button class="btn btn-lg btn-secondary btn-block mt-5" (click)="submit()">
            <span class="text-white">Update</span>
          </button>


        </div>

      </div>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
