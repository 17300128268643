<app-navbar></app-navbar>
<router-outlet></router-outlet>

<!--<div class="row px-xs-5">
  <div class="col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <router-outlet></router-outlet>
  </div>
  <div class="col-sm-1"></div>
</div>-->


