<div>
  <div class="row p-5 user-header">
    <div class="col-sm-12 col-md-4">
      <img class="card-img-top rounded-circle shadow-lg"
           alt="header image"
           [src]="userViewableData?.userHeaderPic"
           data-holder-rendered="true"
           style="height: 225px;
                 width: 100%;
                 display: block;"/>
    </div>
    <div class="col-sm-12 col-md-8">
      <h2 class="bol">{{userViewableData?.name}}</h2>
      <div>
        <p>Member since: <span>{{userViewableData?.regDate | amFromUnix | amDateFormat:'LL'}}</span></p>
      </div>
    </div>
  </div>
  <ul class="nav justify-content-center py-2 border-dark border-top border-bottom shadow">
    <li class="nav-item">
      <a class="btn btn-secondary d-inline-block" routerLinkActive="active-link" [routerLink]="'./'">
        Home
      </a>
      <fa-icon class="mx-2 fontawesome-color" [icon]="faDotCircle"></fa-icon>
      <a class="btn btn-secondary d-inline-block" routerLinkActive="active-link" [routerLink]="'gallery'">
        Gallery
      </a>
      <fa-icon class="mx-2 fontawesome-color" [icon]="faDotCircle"></fa-icon>
      <a class="btn btn-secondary d-inline-block" routerLinkActive="active-link" [routerLink]="'videos'">
        Videos
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>
