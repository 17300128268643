import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticleRoutingModule } from './article-routing.module';
import { ArticleComponent } from './article/article.component';
import { ArticlesComponent } from './articles/articles.component';
import { ArticleAddComponent } from './article-add/article-add.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ArticlePictureEditComponent } from './article-picture-edit/article-picture-edit.component';
import {Nl2BrPipeModule} from "nl2br-pipe";
import {MomentModule} from "ngx-moment";
import {SpinnerModule} from "../spinner/spinner.module";
import { UserArticleMgmtComponent } from './user-article-mgmt/user-article-mgmt.component';
import {MatButtonModule} from "@angular/material/button";
import { ArticleContentComponent } from './article-content/article-content.component';
import {MatDialogModule} from "@angular/material/dialog";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";


@NgModule({
  declarations: [ArticleComponent,
    ArticlesComponent,
    ArticleAddComponent,
    ArticlePictureEditComponent,
    UserArticleMgmtComponent,
    ArticleContentComponent],
  imports: [
    CommonModule,
    ArticleRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    Nl2BrPipeModule,
    MomentModule,
    SpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule
  ],
  exports: [UserArticleMgmtComponent],
  entryComponents: [ArticleContentComponent]
})
export class ArticleModule { }
