import {CompanyModel} from "../component/company/model/company.model";
import {CommunityModel} from "../model/community.model";

export class CommunityTransformer {
  static transformCommunity(json: any): CommunityModel {
    return {
      id: json.id,
      name: json.name,
      logoUrl: undefined,
      systemName: json.systemName,
      description: json.description
    }
  }

  static transformCommunities(arr: any[]): CommunityModel[] {
    const res: CommunityModel[] = [];
    arr.forEach(value => {
      res.push(CommunityTransformer.transformCommunity(value)
      );
    });
    return res;
  }
}
