<div class="row mb-5">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <!--overview-->
    <div *ngIf="organisation?.description && organisation?.description.length > 0" class="mt-5">
      <b class="">Overview</b>
      <p [innerHTML]="organisation.description | nl2br: true"></p>
    </div>

    <!--fellowship-->
    <div class="mt-5">
      <div *ngFor="let fellowshipItem of fellowshipList" class="media mt-3 pr-1">
        <div class="media-body shadow p-3">
          <span>{{fellowshipItem.datePosted  | amFromUnix | amDateFormat:'LL'}}</span>
          <p class="mt-2" [innerHTML]="textFormatService.autoLinkText(fellowshipItem.body) | nl2br: true"></p>
          <img *ngIf="fellowshipItem.picUrl?.length>1" class="img-fluid rounded mx-auto d-block shadow-lg custom-img-fluid"
               [src]="fellowshipItem.picUrl"
               alt="comment picture">

          <!--replies-->
          <div class="d-flex flex-column ml-5 mt-2" *ngFor="let reply of fellowshipItem.replies">
            <!--user image and name-->
            <div class="d-flex flex-row">
              <div>
                <img class="mr-3 rounded-circle"
                     [src]="reply.userImageUrl"
                     alt="image" width="40" height="40">
              </div>
              <div>
                <a [routerLink]="'/user/view/'+reply.userId">
                  <h5 class="mt-0">{{reply.userName}}</h5>
                </a>
                <span>{{reply.datePosted  | amFromUnix | amDateFormat:'LL'}}</span>
              </div>
            </div>

            <!--body-->
            <div>
              <p class="mt-2" [innerHTML]="reply.comment | nl2br: true"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
