import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CompanyAddComponent} from "./company-add/company-add.component";
import {CompanyEditComponent} from "./company-edit/company-edit.component";
import {CorporateViewComponent} from "./corporate-view/corporate-view.component";
import {EmailEnquiryComponent} from "./email-enquiry/email-enquiry.component";
import {ProductViewComponent} from "../product/product-view/product-view.component";
import {ProductListComponent} from "../product/product-list/product-list.component";
import {OrganisationBodyComponent} from "./organisation-body/organisation-body.component";
import {OrganisationVodcastComponent} from "./organisation-vodcast/organisation-vodcast.component";


const routes: Routes = [
  {
    path: 'corporate/add',
    component: CompanyAddComponent
  },
  {
    path: 'corporate/edit/:id',
    component: CompanyEditComponent
  },
  {
    path: 'page/:id',
    component: CorporateViewComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        component: OrganisationBodyComponent
      },
      {
        path: 'products',
        component: ProductListComponent
      },
      {
        path: 'product/:id',
        component: ProductViewComponent
      },
      {
        path: 'vodcast',
        component: OrganisationVodcastComponent
      }
    ]
  },
  {
    path: 'corporate/email/enquiry/:id',
    component: EmailEnquiryComponent
  },
  {
    path: 'corporate/:id/product/:productId',
    component: CorporateViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule {
}
