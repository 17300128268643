import {ArticleReplyModel} from "../model/article-reply.model";
import {ArticleModel} from "../model/article.model";
import {ArticleContentModel} from "../model/article-content.model";

export class ArticleTransformer {

  static transformToArticleModel(json: any): ArticleModel {
    return {
      id: json.id,
      headline: json.headline,
      content: json.articleContentList ? ArticleTransformer.transformTextContentArray(json.articleContentList) : [],
      datePublishedInMillis: json.datePublishedInMilliseconds,
      communityId: json.communityId
    }
  }

  static transformArticleContent(json: any): ArticleContentModel {
    return {
      id: json.id,
      textContent: json.textContent,
      contentTypeId: json.contentTypeId,
      contentTypeName: json.contentTypeName,
      imageUrl: json.imageUrl,
      videoUrl: json.videoUrl
    }
  }

  static transformTextContentArray(jsonArr: any[]): ArticleContentModel[] {
    let arr: ArticleContentModel[] = [];
    for (let json of jsonArr) {
      arr.push(ArticleTransformer.transformArticleContent(json));
    }
    return arr;
  }

  static transformArticleModelArray(jsonArr: any[]): ArticleModel[] {
    let arr: ArticleModel[] = [];
    for (let json of jsonArr) {
      arr.push(ArticleTransformer.transformToArticleModel(json));
    }
    return arr;
  }
}
