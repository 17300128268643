import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ArticleCreateModel} from "../../../model/article-create.model";
import {ArticleService} from "../../../service/article/article.service";
import {ArticleTypeModel} from "../../../model/article-type.model";
import {UserDataModel} from "../../../model/userModel";
import {UserService} from "../../../service/user/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ArticleModel} from "../../../model/article.model";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ArticleContentComponent, ArticleContentData} from "../article-content/article-content.component";

export enum CreateEditMode {
  CREATE,
  EDIT
}


@Component({
  selector: 'app-article-add',
  templateUrl: './article-add.component.html',
  styleUrls: ['./article-add.component.scss']
})
export class ArticleAddComponent implements OnInit {

  formGroup: FormGroup;
  articleTypes: ArticleTypeModel[] = [];
  users: UserDataModel[] = [];
  article: ArticleModel;
  mode: CreateEditMode;
  articleId;
  communityId: number;
  readonly CreateEditMode = CreateEditMode;
  dialogConfig = new MatDialogConfig();

  constructor(private articleService: ArticleService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
    this.formGroup = new FormGroup({
      headline: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit() {
    // this.getArticleTypes();
    this.articleId = this.activatedRoute.snapshot.params.id;
    this.communityId = this.activatedRoute.snapshot.params.communityId

    if (!isNaN(this.articleId)) {
      this.mode = CreateEditMode.EDIT;
      this.getArticle(this.articleId);
    } else {
      this.mode = CreateEditMode.CREATE;
    }
  }

  submit() {
    if (this.formGroup.get) {
      this.articleService.postArticle(this.headline, this.communityId)
        .subscribe((value: ArticleModel) => {
          this.processArticle(value);
        });
    } else {

    }
  }

  getArticleTypes() {
    this.articleService.getArticleTypes()
      .subscribe((res: ArticleTypeModel[]) => {
        this.articleTypes = res;
      });
  }

  findUsers() {
    this.userService.getUserByEmail(this.formGroup.get('emailForSearch').value)
      .subscribe(user => {
        this.users.push(user);
      })
  }

  private getArticle(id: number) {
    this.articleService.getArticle(id)
      .subscribe((article: ArticleModel) => {
        this.processArticle(article);
      })
  }

  private processArticle(article: ArticleModel) {
    this.article = article;
    this.setValuesInFields();
    this.mode = CreateEditMode.EDIT;
  }

  private setValuesInFields() {
    this.formGroup.get('headline').setValue(this.article.headline);
  }

  edit() {
    console.log('edit');
    // const article: ArticleCreateModel = this.transformArticleCreateData();
    this.articleService.editArticle(this.articleId, this.headline)
      .subscribe(article => {
        this.article = article;
      })
  }

  get headline(): string {
    return this.formGroup.get('headline').value.trim();
  }

  openAddContentDialog() {
    if (this.article) {
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = true;
      const d: ArticleContentData = {
        mode: CreateEditMode.CREATE,
        articleId: this.article.id,
        headline: this.article.headline,
        description: undefined,
        contentId: undefined,
        contentType: 1
      };
      this.dialogConfig.data = d;
      this.dialog.open(ArticleContentComponent, this.dialogConfig)
        .afterClosed().subscribe(value => {
        this.getArticle(this.article.id);
      });
    }
  }

  openEdit(content: string, articleContentId: number) {
    if (this.article) {
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus = true;
      const d: ArticleContentData = {
        mode: CreateEditMode.EDIT,
        articleId: this.article.id,
        headline: this.article.headline,
        description: content,
        contentId: articleContentId,
        contentType: 1
      };
      this.dialogConfig.data = d;
      this.dialog.open(ArticleContentComponent, this.dialogConfig)
        .afterClosed().subscribe(value => {
        this.getArticle(this.article.id);
      });
    }
  }
}
