import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ArticleModel} from "../../model/article.model";
import {HttpClient} from "@angular/common/http";
import {ArticleCreateModel} from "../../model/article-create.model";
import {ArticleTypeModel} from "../../model/article-type.model";
import {ArticleTransformer} from "../../transformer/article-transfromer";
import {map} from "rxjs/operators";

export enum PicTypeEnum {
  DISPLAY = 'DISPLAY',
  HEADER = 'HEADER',
  SECTION_1 = 'SECTION_1',
  SECTION_2 = 'SECTION_2',
  SECTION_3 = 'SECTION_3'
}
@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private httpClient: HttpClient) { }

  public getArticles(pageNumber: number, articleTypeId?: number): Observable<ArticleModel[]>{
    const homePageUrl = `web/api/article/list/${pageNumber}`;
    let params = {};
    if (articleTypeId == undefined) {
      params = null;
    }else{
      params = {'articleTypeId': articleTypeId + ''};
    }
    return this.httpClient.get<ArticleModel[]>(homePageUrl, {
      params
    });
  }

  public getAllArticlesByCommunity(communityId: number): Observable<ArticleModel[]>{
    const url = `web/api/article`;
    const params = {'communityId': `${communityId}`};
    return this.httpClient.get(url, {
    params
    }).pipe(
        map((res: any[])=>{
          return ArticleTransformer.transformArticleModelArray(res);
        })
      );
  }

  public getArticle(id: number): Observable<ArticleModel>{
    const homePageUrl = `web/api/article/${id}`;
    return this.httpClient.get<ArticleModel>(homePageUrl)
      .pipe(
        map(res=>{
          return ArticleTransformer.transformToArticleModel(res);
        })
      );
  }

  public postArticles(article: ArticleCreateModel): Observable<ArticleModel> {
    const url = "/web/api/article";
    return this.httpClient.post<ArticleModel>(url, article);
  }

  public postArticle(headline: string, communityId: number): Observable<ArticleModel> {
    const url = "/web/api/article";
    return this.httpClient.post<ArticleModel>(url, {headline, communityId})
      .pipe(
        map(value=>{
          return ArticleTransformer.transformToArticleModel(value);
        })
      );
  }

  public getArticleTypes(): Observable<ArticleTypeModel[]> {
    const url = "/web/api/article-type/all";
    return this.httpClient.get<ArticleTypeModel[]>(url);
  }

/*  public editArticlePhotos(articleId: number,userId: number, formData: FormData): Observable<ArticleModel> {
    const url = `/web/api/article/photo/${articleId}/user/${userId}`;
    return this.httpClient.put<ArticleModel>(url, formData);
  }*/

  public editArticlePhotos(articleId: number, formData: FormData, picType: PicTypeEnum): Observable<ArticleModel> {
    const url = `/web/api/article/photo/${picType}/${articleId}`;
    return this.httpClient.put<ArticleModel>(url, formData);
  }

  public editArticle(articleId: number, headline: string): Observable<ArticleModel> {
    const url = `/web/api/article`;
    return this.httpClient.put<ArticleModel>(url, {id: articleId, headline: headline})
      .pipe(
        map(value=>{
          return ArticleTransformer.transformToArticleModel(value);
        })
      );
  }

  public postArticleContent(content: string,
                            articleId: number,
                            contentTypeId: number,
                            imageUrl: string,
                            videoUrl: string) {
    const url = "/web/api/article-content";
    const data = {
      textContent: content,
      articleId,
      contentTypeId,
      imageUrl,
      videoUrl
    }
    return this.httpClient.post<ArticleModel>(url, data)
      .pipe(
        map(value=>{
          return ArticleTransformer.transformArticleContent(value);
        })
      );
  }

  public editArticleContent(content: string,
                            articleContentId: number,
                            contentTypeId: number,
                            imageUrl: string,
                            videoUrl: string) {
    const url = "/web/api/article-content";
    const data = {
      textContent: content,
      articleContentId,
      contentTypeId,
      imageUrl,
      videoUrl
    }
    return this.httpClient.put<ArticleModel>(url, data)
      .pipe(
        map(value=>{
          return ArticleTransformer.transformArticleContent(value);
        })
      );
  }
}
