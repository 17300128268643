<div class="row">
  <div class="col-sm-0 col-md-1"></div>
  <div class="col-sm-12 col-md-10">
    <!--    about me-->
    <div *ngIf="userViewableData?.aboutMe && userViewableData?.aboutMe.length > 0" class="mt-3 shadow-lg border-0 rounded">
      <span [innerHTML]="textFormatService.autoLinkText(userViewableData.aboutMe) | nl2br"></span>
    </div>

    <!--    fellowship-->
    <div class="mt-5">
      <div *ngFor="let fellowshipItem of fellowshipListItems" class="media mt-3">
        <div class="media-body">
          <span>{{fellowshipItem.datePosted  | amFromUnix | amDateFormat:'LL'}}</span>
          <div class="mt-2" [innerHTML]="textFormatService.autoLinkText(fellowshipItem.body) | nl2br"></div>
          <img *ngIf="fellowshipItem.picUrl?.length>1" class="rounded mx-auto d-block"
               [src]="fellowshipItem.picUrl"
               alt="comment picture" height="350px">
        </div>
      </div>
      <div class=" d-flex justify-content-center my-5">
        <button class="btn btn-lg btn-secondary" (click)="nextFellowshipItemsPage()">More</button>
      </div>
    </div>
  </div>
  <div class="col-sm-0 col-md-1"></div>
</div>
