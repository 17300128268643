import {Action} from "@ngrx/store";
import {AuthenticationModel, UserDataModel, UserModel} from "../../model/userModel";

export const SET_AUTHENTICATION= '[AUTHENTICATION] SET_AUTHENTICATION';
export const SET_AUTHENTICATION_TOKEN= '[AUTHENTICATION] SET_AUTHENTICATION_TOKEN';
export const SET_USER= '[USER] SET_USER';
export const DELETE_USER= '[USER] DELETE_USER';
export const ADD_DATING_NETWORK_USERS= '[USER] ADD_DATING_NETWORK_USERS';
export const SET_DATING_NETWORK_PAGE= '[USER] SET_DATING_NETWORK_PAGE';

export class SetAuthenticationAction implements Action{
  readonly type = SET_AUTHENTICATION;
  constructor(public payload: {authentication: AuthenticationModel}){}
}

export class SetAuthenticationToken implements Action{
  readonly type = SET_AUTHENTICATION_TOKEN;
  constructor(public payload: {token: string}){}
}

export class SetUserAction implements Action{
  readonly type = SET_USER;
  constructor(public payload: {user: UserDataModel}){}
}

export class DeleteUserAction implements Action{
  readonly type = DELETE_USER;
  constructor(){}
}

export class AddDatingNetworkUsers implements Action{
  readonly type = ADD_DATING_NETWORK_USERS;
  constructor(public payload: UserDataModel[]){}
}

export class SetDatingNetworkPage implements Action{
  readonly type = SET_DATING_NETWORK_PAGE;
  constructor(public payload: number){}
}


export type Actions = SetAuthenticationAction | SetUserAction | SetAuthenticationToken | DeleteUserAction
  | AddDatingNetworkUsers | SetDatingNetworkPage;
