import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {UserDataModel} from "../../../model/userModel";
import {FellowshipModel} from "../../../model/fellowship.model";
import {UserService} from "../../../service/user/user.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {ActivatedRoute, Router} from "@angular/router";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {ArticleService} from "../../../service/article/article.service";
import {faDotCircle} from "@fortawesome/free-solid-svg-icons";
import {Autolinker} from "autolinker";
import {TextFormatService} from "../../../service/text-format/text-format.service";

@Component({
  selector: 'app-user-view-home',
  templateUrl: './user-view-home.component.html',
  styleUrls: ['./user-view-home.component.scss']
})
export class UserViewHomeComponent implements OnInit {

  userViewableData: UserDataModel;
  userViewableId: number;
  fellowshipPage = 0;
  fellowshipListItems: FellowshipModel[] = [];
  faDotCircle: any;

  constructor(private userService: UserService,
              private store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private fellowshipService: FellowshipService,
              public textFormatService: TextFormatService) {

    /*icons*/
    this.faDotCircle = faDotCircle;
  }

  ngOnInit() {
    this.userViewableId = this.activatedRoute.snapshot.parent.params.id;
    this.getUser(this.userViewableId);
    this.getFellowshipItems(this.userViewableId, this.fellowshipPage);
  }

  ngOnDestroy(): void {
  }

  getUser(userId: number) {
    this.userService.getUser(userId).subscribe((userViewable: UserDataModel) => {
      this.userViewableData = userViewable;
      if (!this.userViewableData.userHeaderPic || this.userViewableData.userHeaderPic.length == 0) {
        this.userViewableData.userHeaderPic = this.userService.userNoPic;
      }
    })
  }

  getFellowshipItems(userId: number, page: number) {
    this.fellowshipService.getFellowshipListForUser(userId, page)
      .subscribe((fellowshipArray: FellowshipModel[]) => {
        this.fellowshipListItems = this.fellowshipListItems.concat(fellowshipArray);
      })
  }

  nextFellowshipItemsPage() {

  }
}
