import {UserDataModel, UserPicture} from "../model/userModel";

export class UserTransformer{
  public static convertToUser(userJson: any): UserDataModel {
    const user: UserDataModel =   {
      id: userJson.id,
      email: userJson.email,
      name: userJson.name,
      password: null,
      authority: userJson.authority,
      newsNotification: userJson.newsNotification,
      prayerNotification: userJson.prayerNotification,
      editorialNotification: userJson.editorialNotification,
      userHeaderPic: userJson.picUrl ? userJson.picUrl : null,
      aboutMe: userJson.aboutMe,
      location: {
        id: userJson.locationId,
        name: userJson.locationName
      },
      regDate: userJson.regDate,
      pictures: userJson.pictures ? UserTransformer.convertToPictureArray(userJson.pictures) : null,
      singlesNetworkStatus: userJson.singlesNetworkStatus
    };
    return user;
  }

  public static convertToUsers(usersJson: any[]): UserDataModel[]{
    return usersJson.map(value => {
      return UserTransformer.convertToUser(value);
    })
  }

  private static convertToPictureArray(picJsonArray): UserPicture[] {
    let picArr: UserPicture[] = [];
    for (let json of picJsonArray) {
      picArr.push({
        id: json.id,
        url: json.url
      })
    }
    return picArr;
  }


}
