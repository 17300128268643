import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {UserService} from "../../../service/user/user.service";
import {AuthenticationModel, UserDataModel, UserModel} from "../../../model/userModel";
import {CompanyService} from "../../company/service/company.service";
import {CompanyModel} from "../../company/model/company.model";
import {ProductService} from "../../../service/product/product.service";
import {ProductModel} from "../../../model/product.model";
import {ArticleService} from "../../../service/article/article.service";
import {ArticleModel} from "../../../model/article.model";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {NewsService} from "../../news/service/news.service";
import {NewsModel} from "../../../model/news.model";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  authentication: Observable<AuthenticationModel>;
  authenticationSub: Subscription;
  authenticationData;

  userObservable: Observable<UserModel>;
  userSub: Subscription;
  userData: UserDataModel;

  companies: CompanyModel[];
  products: ProductModel[];
  articles: ArticleModel[];
  news: NewsModel[] = [];

  constructor(private userService: UserService,
              private store: Store<AppState>,
              private companyService: CompanyService,
              private productService: ProductService,
              private articleService: ArticleService,
              private router: Router,
              private newsService: NewsService) {
    this.authenticationSub = this.store.select('user').subscribe(user => {
      this.authenticationData = user.authentication;
    });

    this.userSub = this.store.select('user').subscribe(user => {
      if (user.user) {
        this.userData = user.user;
      }
    });
  }

  ngOnInit() {
/*    if (this.userData && this.userData.authority.includes('GUEST')) {
      //redirect guest users to the complete registration page
      this.router.navigate([`/email-verify/${this.userData.id}`])
    } else if (this.userData) {
      this.getUserCompanies(this.userData.id);
      this.getUserProducts(this.userData.id);
      this.getUserArticles();
      this.getNews();
    } else {
      this.router.navigate([`/login`])
    }*/
  }

  ngOnDestroy(): void {
    this.authenticationSub.unsubscribe();
    this.userSub.unsubscribe();
  }

  getUserCompanies(userId: number) {
    this.companyService.getCompanies(userId)
      .subscribe((companies: CompanyModel[]) => {
        this.companies = companies;
      })
  }

  getUserProducts(userId: number) {
    this.productService.getProductsForOrganisation(userId)
      .subscribe((productModels: ProductModel[]) => {
        this.products = productModels;
      })
  }

  getUserArticles() {
    this.articleService.getArticles(0)
      .subscribe(articles => {
        this.articles = articles;
      })
  }

  getNews(){
    this.newsService.getNewsPage(0)
      .subscribe((news: NewsModel[]) => {
        this.news = news;
      })
  }

  isUserAdminRole() {
    return this.userData && this.userData.authority.includes('ADMIN');
  }
}
