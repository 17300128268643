import { Component, OnInit } from '@angular/core';
import {FellowshipModel} from "../../../model/fellowship.model";
import {Subscription} from "rxjs";
import {UserDataModel, UserModel} from "../../../model/userModel";
import {ForumTypeEnum} from "../../../model/forum-type.enum";
import {FellowshipService} from "../../../service/fellowship/fellowship.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {FellowshipreplyModel} from "../../../model/fellowshipreply.model";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {TextFormatService} from "../../../service/text-format/text-format.service";
import {DirectoryModel} from "../../directory/model/directory.model";
import {CompanyModel} from "../../company/model/company.model";
import {FormControl, FormGroup} from "@angular/forms";
import {SetUserOrganisations} from "../../directory/directory.action";
import {CompanyService} from "../../company/service/company.service";

@Component({
  selector: 'app-user-community-home',
  templateUrl: './user-community-home.component.html',
  styleUrls: ['./user-community-home.component.scss']
})
export class UserCommunityHomeComponent implements OnInit {
  fellowshipMessage: string = null;
  fellowshipPhoto;
  fellowshipListItems: FellowshipModel[] = [];
  fellowshipPageCounter = 0;
  userSub: Subscription;
  userData: UserDataModel;
  errorMsg;
  activeForumType: ForumTypeEnum = ForumTypeEnum.FELLOWSHIP;
  allForumType = ForumTypeEnum.ALL;
  fellowshipForumType = ForumTypeEnum.FELLOWSHIP;
  prayerForumType = ForumTypeEnum.PRAYER;
  testimonyForumType = ForumTypeEnum.TESTIMONY;
  postForumType: ForumTypeEnum = ForumTypeEnum.FELLOWSHIP;
  activeFellowshipItem: number = null;
  faSpinner: any;
  comment: string;
  isSavingReply: boolean = false;
  isSaving: boolean = false;

  directorySub: Subscription;
  userOrgs: CompanyModel[];

  constructor(private fellowshipService: FellowshipService,
              private store: Store<AppState>,
              public textFormatService: TextFormatService,
              private companyService: CompanyService) {
    this.faSpinner = faSpinner;

    this.userSub = this.store.select('user').subscribe((user: UserModel) => {
      if (user.user) {
        this.userData = user.user;
      }
    })

    this.directorySub = this.store.select('directory').subscribe((directory: DirectoryModel) => {
      if (directory.userOrganisations && directory.userOrganisations.length > 0) {
        this.userOrgs = directory.userOrganisations;
      }
    })
  }

  postAsFormGp = new FormGroup({
    org: new FormControl('', [])
  });

  ngOnInit() {
    this.getFellowshipItems(this.fellowshipPageCounter, this.activeForumType)
    if (this.userData) {
      this.getUserCompanies(this.userData.id);
    }
  }

  submit() {
    if (!this.userData.id) {
      return false;
    }

    if ((!this.fellowshipMessage || this.fellowshipMessage.length == 0) && !this.fellowshipPhoto) {
      this.errorMsg = 'Invalid. You must type a conversation, upload a picture or both';
      return false;
    }

    this.isSaving = true;
    this.errorMsg = null;

    let form = new FormData();
    if (this.fellowshipPhoto) {
      form.append('fellowshipPic', this.fellowshipPhoto, this.fellowshipPhoto.name);
    }
    form.append('userId', this.userData.id+'');
    form.append('forumType', this.postForumType+'');
    if (this.getOrgId()) {
      form.append('orgId', this.getOrgId());
    }

    if (this.fellowshipMessage && this.fellowshipMessage.length > 0) {
      form.append('comment', this.fellowshipMessage);
    }else{
      form.append('comment', '');
    }
    this.fellowshipService.postFellowship(form)
      .subscribe((fellowship: FellowshipModel) => {
        this.fellowshipMessage = null;
        this.fellowshipPhoto = null;
        this.fellowshipListItems.unshift(fellowship);
        this.isSaving = false;
      }, error => {
        this.isSaving = false;
      });
  }

  onChangeFellowshipPhoto(event) {
    this.fellowshipPhoto = event.target.files[0];
  }

  getFellowshipItems(page: number, type: ForumTypeEnum) {
    this.fellowshipService.getFellowshipList(page, type)
      .subscribe((fellowshipArray: FellowshipModel[]) => {
        this.fellowshipListItems = this.fellowshipListItems.concat(fellowshipArray);
      })
  }

  nextFellowshipItemsPage() {
    this.fellowshipPageCounter++;
    this.getFellowshipItems(this.fellowshipPageCounter, this.activeForumType);
  }

  ngOnDestroy(): void {
  }

  onNavClick(forumType: ForumTypeEnum) {
    this.activeForumType = forumType;
    this.postForumType = forumType;
    this.fellowshipListItems = [];
    this.fellowshipPageCounter = 0;
    this.getFellowshipItems(this.fellowshipPageCounter, this.activeForumType);
  }

  isNavSelected(forumType: ForumTypeEnum) {
    return forumType === this.activeForumType;
  }

  showReplyBox(fellowshipId: number):boolean {
    return this.activeFellowshipItem && this.activeFellowshipItem === fellowshipId;
  }

  setActiveFellowshipItem(fellowshipId: number) {
    this.comment = null;
    this.activeFellowshipItem = fellowshipId;
  }

  submitReply(fellowshipItem: FellowshipModel) {
    this.isSavingReply = true;
    this.fellowshipService.postFellowshipReply(this.comment,this.userData.id,fellowshipItem.id)
      .subscribe((response: FellowshipreplyModel) => {
          this.isSavingReply = false;
          fellowshipItem.replies.push(response)
        },
        error => {
          this.isSavingReply = false;
        })
  }

  hasUserOrgs(): boolean {
    return this.userOrgs && this.userOrgs.length > 0;
  }

  getOrgId(){
    const postAsVal = this.postAsFormGp.get('org').value;
    if (postAsVal && postAsVal > 0) {
      return postAsVal;
    }else{
      return null;
    }
  }

  getUserCompanies(userId: number) {
    this.companyService.getCompanies(userId)
      .subscribe((companies: CompanyModel[]) => {
        this.store.dispatch(new SetUserOrganisations({companies: companies}))
      })
  }
}
