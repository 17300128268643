import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CreateEditMode} from "../article-add/article-add.component";
import {ArticleService} from "../../../service/article/article.service";

export interface ArticleContentData{
  mode: CreateEditMode;
  articleId: number;
  headline: string;
  description: string;
  contentId: number;
  imageUrl?: string;
  videoUrl?: string;
  contentType: number;
}

@Component({
  selector: 'app-article-content',
  templateUrl: './article-content.component.html',
  styleUrls: ['./article-content.component.scss']
})
export class ArticleContentComponent implements OnInit {

  headline: string;
  body: string;
  form: FormGroup;
  createMode: CreateEditMode = CreateEditMode.CREATE;
  editMode: CreateEditMode = CreateEditMode.EDIT;

  constructor(public dialogRef: MatDialogRef<ArticleContentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ArticleContentData,
              private articleService: ArticleService) {
    this.form = new FormGroup({
      description: new FormControl(data.description, Validators.required),
      imageUrl: new FormControl(data.imageUrl),
      videoUrl: new FormControl(data.videoUrl),
      contentTypeId: new FormControl(data.contentType, Validators.required)
    })

  }

  ngOnInit(): void {
    console.log(this.data);
  }

  get contentTypeId(): number {
    return this.form.get('contentTypeId').value;
  }

  get imageUrl(): string{
    return this.form.get('imageUrl').value ? this.form.get('imageUrl').value.trim() : undefined;
  }

  get videoUrl(): string{
    return this.form.get('videoUrl').value ? this.form.get('videoUrl').value.trim() : undefined;
  }

  submit() {
    this.articleService.postArticleContent(this.form.get('description').value,
      this.data.articleId,
      this.contentTypeId,
      this.imageUrl,
      this.videoUrl)
      .subscribe(value => {
        this.dialogRef.close(value);
      });
  }

  editContent() {
    this.articleService.editArticleContent(
      this.form.get('description').value,
      this.data.contentId, this.contentTypeId,
      this.imageUrl,
      this.videoUrl)
      .subscribe(value => {
        this.dialogRef.close(value);
      });
  }

  cancel() {
    this.dialogRef.close();
  }

}
